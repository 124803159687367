
.services-section{
    margin-top: 100px;
    text-align: center;
}  
.services-section>h1{
   font-size:40px;
}  
  .parent-container {
    /*  */
    display : flex; 
    align-items : center;
    justify-content : center;  
    min-height : 600px;
    margin-top: -50px;
  }
  
  .child-container {
    position : relative;
    width : 1100px;
    display : flex;
    align-items : center;
    justify-content : space-around;
    flex-wrap : wrap;
    padding : 30px;  
    color: #FFFFFF;
  }
  
  .child-container .card {
    box-sizing : border-box;
    position: relative;
    max-width : 300px;
    height : 215px;  
    background-color : #1A1919;
    /* margin : 30px 20px; */
    padding : 20px 15px;
    text-align: justify;
    display : flex;
    flex-direction : column;
    box-shadow : 0 5px 20px rgba(0,0,0,0.5);
    transition : 0.3s ease-in-out;
    border-radius : 8px;
  }
  .child-container .card:hover {
    height : 330px;    
  }
  
  
  .child-container .card .image {
    position : relative;
    width : 260px;
    height : 100%;
    top : -40%;
    left: 8px;
    box-shadow : 0 5px 20px rgba(0,0,0,0.2);
    z-index : 1;
  }
  
  .child-container .card .image img {
    max-width : 100%;
    border-radius : 8px;
  }
  
  .child-container .card .content {
    position : relative;
    top : -140px;
    padding : 10px 15px;
    color : #111;
    text-align : center;
    color: white;
    visibility : hidden;
    opacity : 0;
    transition : 0.3s ease-in-out;
      
  }
  
  .child-container .card:hover .content {
     margin-top : 30px;
     visibility : visible;
     opacity : 1;
     transition-delay: 0.2s;
    
  }

  /* Media Queries for mobile responsiveness */
@media (max-width: 768px) {
  .services-section>h1 {
      font-size: 30px; /* Slightly smaller font size on mobile */
  }

  

  .parent-container {
    flex-direction: column; /* Stacks flex items vertically on small screens */
  }
  
  .child-container {
    width: 80%; /* Adjust width to full on smaller screens */
    justify-content: center; /* Center content */
    align-items: center;
    margin-top: 50px; 
  }
  
  .child-container .card {
      margin-top: 100px; /* Adjust margin for better spacing */
      padding: 13px; /* Adjust padding for consistency */
  }

}

