.sticky-btn {
    font-family: "Montserrat";
    font-size: 1.1rem;
    border-radius: 6px;
    padding: 12px;
    position: fixed;
    bottom: 60px;
    right: -10px;
    z-index: 10;
    background-color: transparent;
    border: 1px solid #F2E74E;
    color: white;
    cursor: pointer;
    width: 160px;
    font-weight: 500;
    transition:  0.2s ease-in-out; /* Add transition for opacity */
    opacity: 0; /* Initially set opacity to 0 */
}

.sticky-btn.show {
    opacity: 1; /* Change opacity to 1 when button is visible */
}

.sticky-btn:hover {
    background-color: #F2E74E;
    color: black;
    width: 200px;
    transition: 0.3s ease-in-out;
}

/* Media query for mobile view */
@media (max-width: 768px) {
    .sticky-btn {
        width: 100%; /* Set button width to 100% of viewport */
        bottom: 0; /* Align button to the bottom of the viewport */
        left: 0; /* Set button to start from the left side */
        right: 0; /* Set button to end at the right side */
        border-radius: 0; /* Remove border-radius for a straight edge */
        background-color: #F2E74E;
        color: black;
    }
    .sticky-btn:active, .sticky-btn:hover {
        width: 100%;
        color: black;
        transition: 0.3s ease-in-out;
    }
}