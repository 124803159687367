body {
  background-color: black;
  color: white;
  font-family: "Montserrat";
}

.connect-now-button{
  font-family: "Montserrat";
  font-size: 18px;
  height: 40px;
  width: 190px;
  /* background-color: rgb(26, 25, 25); */
  background-color: rgb(44, 43, 43);
  border: none;
  color: #FEF29C;
}

.connect-now-button:hover{
  cursor: pointer;
  text-shadow: 0 0 20px #FEF29C;
}

.header{  
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 5px; */
  font-size: larger;
}

.header>h1{ 
transition: 0.5s; 
}

.header>h1:hover{  
  transition: 0.5s;
  /* text-shadow: 0 0 20px #ffff02; */
  text-shadow: 0 0 20px #FEF29C;
  cursor: pointer;
}

.banner{
  margin-top: 50px;
  
}

.banner-text>p{
  font-weight: 100;
  text-align: justify;
  padding: 10px;
  font-size: 1.4rem;
}

.banner-text{
  background-color: rgb(26, 25, 25);
  text-align: center;
  padding: 30px;
  margin : auto;
  width: 80%;
  border-radius: 8px;
  /* box-shadow: 0px 0px 500px #FEF29C; */
}
.banner-text>h1{
  font-size: 4rem;
}

.yellow-font{
  /* color: #ffff02; */
  color: #FEF29C;
}

.levelaid-logo{
  object-fit: cover;
  height: 70px;
}
.levelaid-logo-container{
display: flex;
}

@media only screen and (max-width: 768px) {
  .header {
    /* flex-direction: column; */
    text-align: center;
    align-items: center;
  }
  .banner{
    width: 95%;
  }

 .banner-text>h1{
    font-size: 3rem ;
  }
  .client-img {
    /* margin: 10px; */
  }
  
  .banner-text>p {
    font-size: 1.3rem;
  }
  .banner-text {
    width: 90%;
  }
}