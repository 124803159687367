/* Main container styles */
.container {
	margin: auto;
	max-width: 1200px; /* Adjust the maximum width as needed */
  }
  
  /* Footer styles */
  .footer {
	padding: 40px 0;
  }
  
  .footer-row {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
  }
  
  .footer-col {
	width: 100%;
	text-align: center;
	justify-content: center;
	margin-bottom: 20px; /* Add spacing between rows */
  }
  
  .footer-col ul {
	list-style: none;
	padding: 0;
  }
  
  .footer-col ul li {
	font-weight: 150;
	margin-bottom: 10px;
  }
  
  .footer-col ul li a {
	text-align: center;
	color: #e6e3e3;
	text-decoration: none;
	transition: all 0.3s ease;
  }
  
  .footer-col ul li a:hover {
	color: #ffffff;
  }
  
  /* Social links styles */
  .social-links {
	display: flex;
	justify-content: center;
  }
  
  .social-links a {
	display: inline-block;
	align-items: center;
	height: 40px;
	width: 40px;
	background-color: rgba(255, 255, 255, 0.2);
	margin-right: 10px; /* Adjust the spacing as needed */
	text-align: center;
	line-height: 40px;
	border-radius: 50%;
	color: #ffffff;
	transition: all 0.5s ease;
  }
  
  .social-links a:hover {
	color: #24262b;
	background-color: #fef29c;
  }

  .footer-links{
	justify-content: space-evenly;
	align-items: start;
	/* text-align:justify ; */
	display: flex;
  }
  
  .levelaid-footer-logo-container{
	display: flex;
	text-align: center;
	align-items: center;
	justify-content: center;
  }

  /* Copyright text styles */
  .copyright {
	text-align: center;
	color: #ffffff;
	font-weight: 140;
  }
  
  /* Responsive styles for mobile devices */
  @media (max-width: 767px) {
	.footer-col {
	  width: 100%;
	  text-align: center;
	  margin-bottom: 0; /* Remove spacing between rows for mobile */
	}
	.logo-links{
		align-items: center;
		justify-content: center;
	}
	.copyright {
		padding-bottom: 30px;
	}
  }
  