.reviews-section{
    text-align: center; 
}

.reviews-card-container{
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content:center; /* Center items horizontally */
    flex-wrap: wrap; /* Allow items to wrap to the next line */
}
.review-card{
    padding: 20px;
    margin-top: 50px;
    width: 300px;
    height: 400px;
    text-align:left;
}

.review-card:hover{
    background-color: rgb(20, 20, 20);
cursor: pointer;
}

.yellow-font{
    /* color: #f2e74e; */
    color : #FEF29C;
  }

  @media (max-width: 768px) {
    .review-card{
        height: auto;
        margin: 0;
        text-align: justify;
    }

  }