.clients-banner {
  padding: 50px;
  text-align: center;
  margin: auto;
  max-width: 80%; /* Limit the maximum width to prevent overflow */
}

.clients-banner > h1 {
  /* Adjust font size if needed */
}

.clients-banner > p {
  margin-top: 60px;
  font-size: 20px;
  font-weight: 180; /* Correct the value to 'normal' for font-weight */
}

.client-img-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap; /* Allow images to wrap on smaller screens */
  margin-top: 20px; /* Reduce margin for better spacing on mobile */
}

.client-img {
  margin: 10px; /* Adjust margin for better spacing between images */
  max-width: 100%; /* Ensure images don't exceed the width of their container */
  height: auto; /* Maintain aspect ratio */
}
