#terms-of-use h2 {
  color: #fef29c;
  font-size: 24px;
  margin-bottom: 20px;
}

#terms-of-use p {
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 20px;
}

#terms-of-use h3 {
  font-size: 20px;
  margin-bottom: 10px;
}

#terms-of-use a {
  color: #007bff;
  text-decoration: none;
}

#terms-of-use a:hover {
  text-decoration: underline;
}

#terms-of-use{
  padding: 40px;
}
