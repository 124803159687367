.numbers-section{
    text-align: center; 
}

.numbers-card-container{
    padding: 60px;
    display: flex;
    justify-content:center; /* Center items horizontally */
    flex-wrap: wrap; /* Allow items to wrap to the next line */
}
.numbers-card{
    padding: 20px;
    margin-top: 50px;
    width: 200px;
    height: 300px;
    margin: 0 30px; /* Adjust margin as needed */
    text-align:left;
    border-radius: 8px;
    background-color: rgb(20, 20, 20);
    text-align:start;
}

.numbers-card-heading{
    height: 80px;
}


.numbers-font{
    /* color: #f2e74e; */
    color : #FEF29C;
  }

  @media (max-width: 768px) {
    .numbers-card{
        width: 100%;
        height: 100%;
        margin: 10px;
    }
    .numbers-card-container{
        margin: auto;
        width: 80%;
        padding: 10px;
    }
  }