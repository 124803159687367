#contact {
  padding: 20px;
}

.contact-container {
  max-width: 800px;
  margin: 0 auto;
}

.contact-container h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.contact-container h3 {
  font-size: 20px;
  margin-top: 30px;
  margin-bottom: 10px;
}

.contact-container p {
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 20px;
}

.contact-container a {
  color: #007bff;
  text-decoration: none;
}

.contact-container a:hover {
  text-decoration: underline;
}
